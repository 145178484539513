<template>
    <modal-lateral ref="selectCliente" :titulo="titulo">
        <div class="row m-3">
            <el-select
            v-model="value_cliente"
            filterable
            remote
            reserve-keyword
            :allow-create="casuales"
            :remote-method="buscar_clientes"
            placeholder="Nombre del cliente"
            class="w-100"
            @change="seleccionar_cliente"
            >
                <el-option
                v-for="(item, index2) in clientes"
                :key="`key-2-${index2}`"
                :label="item.nombre"
                :value="item.id_user"
                >
                    <span style="float: left">{{ item.nombre }}</span>
                    <span
                    style="float: right; color: #8492a6; font-size: 13px"
                    >{{ item.telefono }}</span>
                </el-option>
            </el-select>
        </div>
    </modal-lateral>
</template>

<script>
import PedidosNew from "~/services/pedidos/pedidos-tendero";
export default {
    data(){
        return{
            pedido:{},
            clientes:[],
            value_cliente:'',
            tipoCliente:1
        }
    },
    computed:{
        casuales(){
            if(this.tipoCliente === 1) return false
            if(this.tipoCliente === 0) return true
            return false
        },
        titulo(){
            if(this.tipoCliente === 1) return 'Agregar Cliente'
            if(this.tipoCliente === 0) return 'Agregar cliente que no usa la App'
            return 'Agregar Cliente'
        }
    },
    methods: {
        toggle(caso){
            this.tipoCliente = caso
            this.value_cliente = null
            this.clientes = []
            if(this.tipoCliente === 1){
                this.buscar_clientes()
            }
            this.$refs.selectCliente.toggle()
        },
        seleccionar_cliente(val){
            if (_.isNumber(val)){
                let cliente = this.clientes.find((o) => o.id_user == val);

                cliente.tipo = 1

                this.$emit('selected',cliente)

            } else {
                let cliente = this.clientes.find((o) => o.nombre == val);

                if (cliente === undefined){
                    cliente = {
                        nombre:val,
                        telefono:''
                    }

                    cliente.tipo = 0
                    this.$emit('selected',cliente)
                } else {

                    cliente.tipo = 0
                    this.$emit('selected',cliente)

                }
            }
            this.$refs.selectCliente.toggle()
        },
        async buscar_clientes(query=''){
            const busqueda = async() => {
                let params = {
                    query,
                    caso:this.tipoCliente
                };
                const { data } = await PedidosNew.get_clientes(params);
                if(this.tipoCliente === 1){
                    this.clientes = data.clientes_sistema
                    return
                }
                if(this.tipoCliente === 2){
                    this.clientes = []

                    for (const cc of data.clientes_casuales){
                        this.clientes.push({
                            id_user: cc.nombre,
                            telefono: cc.telefono,
                            nombre: cc.nombre,
                        });
                    }
                }

            };
            await this.delay(busqueda);
        },
    }
}
</script>

<style lang="css" scoped>
</style>
